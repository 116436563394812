@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

* {
  font: 16px "Poppins", sans-serif;
}

body {
  margin: 0;
  font: 16px "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9fafc;
}

code {
  font: 16px "Poppins", sans-serif;
}

.ant-table-cell {
  padding: 5px !important;
}